.viewAllSection{
    background-color: white !important;
    z-index: 1;
    width: 100%;
    padding: 30px 5px;
}
.colorViewAllSection{
    width: 90%;
    background-color: #F6F6F6;
    z-index: 1;
    margin: 10px auto;
    text-align: left;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 20rem;
}
.viewAllTitle{
    color: black;
    font-size: 35px;
    width: 100%;
    margin-top: 5rem;
}
.colorViewAllLeft{
    width: 60%;
}
.colorViewAllRight{
    width: 40%;
    padding: 0px !important;
    margin: 10px 0px !important;
    height: 20rem;
    grid-column-gap: 0px;
    background-image: url("https://assets-global.website-files.com/65ec8403fa620f5552b86fdc/65edccbace43d3b1a615e90c_%2305.svg");
    background-repeat: no-repeat;
    display: grid;
}
.callMeLink{
    padding-top: 30px;
    padding-left: 0rem;
}
.callMeLink a {
    text-decoration: underline;
    color: black;
    font-size: 18px;
    font-weight: 500;
}
@media (max-width: 550px) {
   .colorViewAllRight{
    display: none;
   }
   .callMeLink{
    padding-bottom: 3rem;
   }
   .viewAllTitle{
    margin-top: 2rem;
    }
    .colorViewAllLeft{
        width: 100%;
    }
}
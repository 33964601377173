.footerSection{
    width: 100%;
    background-color: #f6f6f6;
}

.topFooterSection{
    padding: 3rem 0px 0px 0px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid black;
    width: 90%;
    margin: 1px auto;
}
.options span a{
    display: inline;
    padding: 20px;
    justify-content: space-between;
    color: black;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
}
.socialMedia a i {
    font-size: 20px;
    color: black;
    padding: 10px;
}
.bottomFooterSection{
    display: flex;
    justify-content: space-between;
    padding: 0px 3rem;
}
.bottomFooterLeftSection, .bottomFooterRightSection p {
    font-weight: 600;
}
@media (max-width: 550px) {
    .topFooterSection{
        text-align: left;
        padding: 20px 0px;
        }
    .options span a{
        display: flex;
        padding: 5px 0px 5px 0px ;
        }
        .socialMedia a i {
        font-size: 25px;
        padding: 5px 0px;
        }
        .bottomFooterSection{
            padding: 0px 1.5rem;

        }
}

.fiverrName{
    width: 95%;
    margin: 0px auto;
    font-size: 5rem;
    font-weight: 400;
    text-align: left;
    padding: 20px 10px 0px 20px;
    z-index: 6;
    text-transform: uppercase;
    mix-blend-mode: exclusion;
    color: black;
    border-bottom: 1px solid black;

}
.fiverrName:hover {
    cursor: pointer;
    color: antiquewhite;
    border-bottom: 4px solid antiquewhite;
    mix-blend-mode:exclusion;

}
.videoBackground{
    position: fixed;
    z-index: -100;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    margin: 0px;
    padding: 0px;
    object-fit: cover;
}
.viewAllButton{
    color: black;
    z-index: 1;
    background-color: transparent;
    text-align: left;
    padding: 30px 0px 0px 30px;
    font-size: 23px;
    
}
.arrowDownSection{
    color: black;
    z-index: 1;
    text-align: left;
    padding: 0px 0px 0px 30px;
    font-size: 23px;
}
.arrowDown{
    color: black;
    z-index: 1;
    text-align: left;
    padding: 30px;
    font-size: 23px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 15px 20px;
    text-decoration: none;
}

@media (max-width: 550px) {
    .fiverrName{
        font-size: 3rem;
        font-weight: 600;
        padding: 20px 10px 0px 0px;
    }
}
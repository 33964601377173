
.googleName{
    width: 95%;
    margin: 50px auto 0px auto;
    font-size: 5rem;
    font-weight: 400;
    text-align: left;
    padding: 20px 10px 0px 20px;
    z-index: 6;
    text-transform: uppercase;
    mix-blend-mode: exclusion;
    color: black;
    border-bottom: 1px solid black;
}

.googleName:hover {
    cursor: pointer;
    color: antiquewhite;
    border-bottom: 4px solid antiquewhite;
    mix-blend-mode:exclusion;
}

.videoBackground{
    position: fixed;
    z-index: -100;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    margin: 0px;
    padding: 0px;
    object-fit: cover;
}
@media (max-width: 550px) {
    .googleName{
        font-size: 3rem;
        font-weight: 600;
        padding: 20px 10px 0px 0px;
    }
}
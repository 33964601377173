.appHeader{
    height:60px;
    backdrop-filter: blur(5px);
    color: black;
    border-bottom: 1px solid black;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
}
.appHeader .mainHeader{
    display: flex;
    justify-content: space-between;
}
.appHeader ul li {
    padding: 20px 20px 1px 20px;
    display: inline;
    list-style-type: none;
    text-align: left;
}
.appHeader ul li:hover {
    cursor: pointer;
}
.searchButton{
    padding: 20px 20px 1px 20px;
    text-decoration: none;
    color: black;
    font: 13px;
    margin-right: 30px;
}
.searchButton i {
    padding-left: 10px;
}
.portfolio{
    text-align: left;
    padding: 5px 20px 1px 20px;
    font-weight: 700;
}

@media (max-width: 550px) {
    .searchButton{
       display: none;
    }
    .appHeader ul{
        padding-left: 15px;
    }
    .appHeader ul li {
    padding: 10px 20px 1px 0px;
    }
    .portfolio{
        font-size: 15px;
        font-weight: 600;
        padding: 10px 10px;
    }
    }